import React from 'react'
import Girl from '../assets/Girl.jpg'
import Main from '../assets/Main.jpg'
import Salon from '../assets/sample.jpeg'
import Whole from '../assets/whole.jpg'
import Whole2 from '../assets/whole2.jpg'
import Whole3 from '../assets/whole3.jpg'
import Room from '../assets/room1.jpg'
import Room2 from '../assets/room2.jpg'
import Sitting from '../assets/sitting.jpg'
import Nail from '../assets/nail.jpg'
import Pretty from '../assets/pretty.jpg'
// Importing all the required pictures 



// This section is bassically showing all the imported pictures in the main Home page as gallery section 
const Gallary = () => {
    return (
        <div className='max-w-[1240px] mx-auto py-8 px-4 bg-black rounded-md'>
            <h1 className='flex font-mono justify-center text-4xl font-m-bold text-[#e0c184] italic'>Gallery</h1>
            <p className='flex font-mono justify-center text-xl text-[white] pt-1'>Look at some of the pictures</p>
            <div className='grid grid-rows-none md:grid-cols-5 py-4 gap-2 md:gap-4'>
                <img className='w-full h-full object-cover col-span-2 md:col-span-2 row-span-2' src={Main} alt="" />
                <img className='w-full h-full object-cover ' src={Nail} alt="" />
                <img className='w-full h-full object-cover' src={Sitting} alt="" />
                <img className='w-full h-full object-cover ' src={Room2} alt="" />
                <img className='w-full h-full object-cover' src={Pretty} alt="" />
                <img className='w-full h-full object-cover md:col-span-2 md:block hidden' src={Whole} alt="" />
            </div>
        </div>
    )
}

export default Gallary