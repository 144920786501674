import React, { useState } from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { Link } from 'react-router-dom'

// This Navbar function is for toggle between the phone navbar menu 
const Navbar = () => {

    const [nav, setNav] = useState(false)

    const handleNav = () => {
        setNav(!nav)
    }

    // This section is about responsive navbar for small devices and big ones as well 
    return (
        <div className='my-2
        mx-2'>
            <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto  px-4 text-white bg-black rounded-md'>
                <h1 className='w-full text-3xl font-sans  text-[#e0c184]'>PRETTY</h1>
                <ul className='hidden md:flex ' >

                    <Link to="" className='p-4 hover:bg-[#6b6b6b] rounded-md'>Home</Link>
                    <Link to="services" className='p-4 hover:bg-[#6b6b6b] rounded-md'>Services</Link>
                    <Link to="about" className='p-4 hover:bg-[#6b6b6b] rounded-md'>About</Link>

                    <a className='p-4 hover:bg-[#6b6b6b] rounded-md' href="https://www.fresha.com/a/pretty-salon-and-spa-upper-hutt-205-207-main-street-xocukhjs/all-offer?venue=true" target="_blank" rel="noopener noreferrer">Book</a>


                </ul>
                <div onClick={handleNav} className='block md:hidden'>
                    {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu scale={20} />}

                </div>
                <div className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r bg-black ease-in-out duration-500' : 'fixed left-[-100%]'}>
                    <h1 className='w-full text-3xl font-bold text-[#e0c184] m-4'>PRETTY</h1>

                    <ul className='uppercase '>
                        <li className='p-4 border-b'><Link to="" className='p-4'>Home</Link></li>
                        <li className='p-4 border-b'><Link to="services" className='p-4'>Services</Link></li>
                        <li className='p-4 border-b'><Link to="about" className='p-4'>About</Link></li>
                        <li className='p-4'><a className='p-4' href="https://www.fresha.com/a/pretty-salon-and-spa-upper-hutt-205-207-main-street-xocukhjs/all-offer?venue=true" target="_blank" rel="noopener noreferrer">Book</a></li>
                        {/* <Link to="" className='p-4 border-b'>Home</Link>
                        <Link to="services" className='p-4 border-b'>Services</Link>
                        <Link to="about" className='p-4 border-b'>About</Link>
                        <a className='p-4' href="https://www.fresha.com/a/pretty-salon-and-spa-upper-hutt-205-207-main-street-xocukhjs/all-offer?venue=true" target="_blank" rel="noopener noreferrer">Book</a> */}
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default Navbar