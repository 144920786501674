import React from 'react'
import sample from '../assets/sample.jpeg'
import Front2 from '../assets/front2.jpg'

// This section is creating a booking section and exporting it to the home page 
const Booking = () => {
    return (
        <div className='my-2 mx-4'>
            <div className='max-w-[1240px] py-8 px-14 rounded-md mx-auto my-4'>
                <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 '>
                    {/* <img className='w-[500px] mx-auto my-4' src={sample} alt="Smilling Girl Image" /> */}
                    <div className='flex flex-col justify-center'>
                        <h1 className='flex font-mono text-4xl text-[#e0c184] italic p-4'>Discover Your True Beauty</h1>
                        <p className='flex font-mono justify-start text-md text-white p-4'>Are you ready to transform your look and boost your confidence? At PRETTY Salon, we believe that everyone deserves to feel beautiful, and we're here to make that happen. Our expert team of stylists and beauticians are dedicated to providing you with the best beauty services that cater to your individual needs.</p>
                        <div className='flex justify-center'>
                            {/* <h1 className='p-3 bg-white hover:bg-[#e0c184] rounded-md w-20 text-xl font-md-bold m-4'>BOOK</h1> */}
                    <a className='mt-4 px-4 py-2 bg-[#e0c184] hover:bg-[white] rounded-md' href="https://www.fresha.com/a/pretty-salon-and-spa-upper-hutt-205-207-main-street-xocukhjs/all-offer?venue=true" target="_blank" rel="noopener noreferrer">Book</a>

                            
                            
                        </div>
                    </div>
                    <img className='w-[500px] mx-auto my-4' src={Front2} alt="Salon's front pic" />


                </div>
            </div>
        </div>
    )
}

export default Booking