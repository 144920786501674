import React from "react";
import Navbar from "./components/Navbar";
import Hook from "./components/Hook";
import Gallary from "./components/Gallary";
import Footer from "./components/Footer";
import Location from "./components/Location";
import Booking from "./components/Booking";
import Services from "./components/Services";


function Home() {
    return (
        <div>
            <Hook />
            <Booking />
            <Services />
            <Gallary />
            <Location />
        </div>
    );
}

export default Home;
