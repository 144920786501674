import React from 'react'
import {
    FaDribbleSquare,
    FaFacebookSquare,
    FaGithubSquare,
    FaInstagram,
    FaTwitterSquare,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'


// This is a footer section which is being imported to App.js 
const Footer = () => {
    return (
        <div className=' mx-4 my-2'>
            <div className='max-w-[1240] mx-auto  py-2  grid lg:grid-cols-3 gap-8 text-white bg-black rounded-md  '>
                <div className='m-2'>
                    <h1 className='w-full text-3xl font-sans text-[#e0c184]'>PRETTY</h1>
                    <p className='py-4'>Pretty Salon and Spa offers a luxurious experience with top-notch beauty services.</p>
                    <div className='flex justify-start md:w-[75%] px-2'>
                        <a href="https://www.facebook.com/people/Pretty-Salon-And-Spa/61558404809533/" target="_blank"><FaFacebookSquare size={30} className='m-2' /></a>
                        <a href="https://www.instagram.com/prettysalonandspa?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank"><FaInstagram size={30} className='m-2' /></a>


                        {/* <FaFacebookSquare size={30} />
                        <FaFacebookSquare size={30} /> */}

                    </div>
                </div>
                <div className='lg:col-span-2 flex justify-evenly px-4 mt-6'>
                    <div>
                        {/* <h6 className=' font-medium text-gray-400'>Solutions</h6> */}
                        <ul>
                            {/* <li className='py-2 text-sm '>Terms & Conditions</li> */}
                            <Link to="terms" className=''>Terms & Conditions</Link>
                            <li className='py-2 text-sm '>Careers</li>
                        <a href="https://www.facebook.com/people/Pretty-Salon-And-Spa/61558404809533/" target="_blank">Facebook</a>
                        <br />
                        <a href="https://www.instagram.com/prettysalonandspa?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">Instagram</a>


                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Footer