import React from "react";
import Navbar from "./components/Navbar";
import Hook from "./components/Hook";
import Gallary from "./components/Gallary";
import Footer from "./components/Footer";
import Location from "./components/Location";
import Booking from "./components/Booking";
import Services from "./components/Services";


function Terms() {
    return (
        <div className='my-2 mx-4'>
            <div className='max-w-[1240px] bg-white py-8 px-14 rounded-md mx-auto my-4'>
                <div className='max-w-[1240px] mx-auto flex justify-center'>
                    {/* <img className='w-[500px] mx-auto my-4' src={Girl} alt="Smilling Girl Image" /> */}
                    <div className='flex flex-col justify-center'>
                        <h1 className='flex font-mono justify-start text-xl font-m-bold text-[#e0c184]'>Pretty Salon and Spa</h1>
                        <h1 className='flex font-mono justify-start text-sm font-bold text-[#6b6b6b]'>1. Appointments

1.1. Booking: Appointments can be made in person, by phone, or through our online booking system.
1.2. Confirmation: All appointments must be confirmed at least 24 hours in advance.
1.3. Late Arrivals: Clients arriving more than 15 minutes late may need to reschedule, and a late arrival fee may be applied.
1.4. Cancellations: Cancellations must be made at least 24 hours in advance. Failure to do so may result in a cancellation fee.

2. Payments

2.1. Payment Methods: We accept cash, credit/debit cards, and electronic payments.
2.2. Deposits: Some services may require a deposit at the time of booking.
2.3. Refunds: All services are non-refundable. If you are dissatisfied with a service, please notify us within 48 hours, and we will address your concerns.

3. Gift Cards and Vouchers

3.1. Validity: Gift cards and vouchers are valid for 12 months from the date of purchase.
3.2. Non-Transferable: Gift cards and vouchers are non-transferable and cannot be exchanged for cash.
3.3. Lost/Stolen: We are not responsible for lost or stolen gift cards and vouchers.

4. Health and Safety

4.1. Medical Conditions: Clients are required to inform us of any medical conditions or allergies prior to treatment.
4.2. Hygiene: We adhere to strict hygiene protocols to ensure a safe environment for all clients.
4.3. Minors: Clients under the age of 18 must have parental consent for certain treatments.

5. Personal Belongings

5.1. Responsibility: Pretty Salon and Spa is not responsible for the loss or damage of personal belongings.
5.2. Valuables: Clients are advised to keep valuables with them at all times.

6. Conduct

6.1. Behavior: Clients are expected to behave in a respectful and courteous manner towards staff and other clients.
6.2. Right to Refuse Service: We reserve the right to refuse service to anyone displaying inappropriate behavior.

7. Privacy

7.1. Data Protection: We are committed to protecting your privacy and will not share your personal information with third parties without your consent.
7.2. Communication: By providing your contact information, you agree to receive communication from Pretty Salon and Spa regarding your appointments and promotions.

8. Changes to Terms and Conditions

8.1. Modifications: Pretty Salon and Spa reserves the right to modify these terms and conditions at any time. Changes will be posted on our website and in the salon.
8.2. Acceptance: Continued use of our services constitutes acceptance of the updated terms and conditions.

9. Liability

9.1. Waiver: By receiving services at Pretty Salon and Spa, you acknowledge and accept that all treatments are provided at your own risk.
9.2. Limitations: Our liability for any claim related to our services is limited to the amount you paid for the service in question.

By booking an appointment or purchasing a service at Pretty Salon and Spa, you agree to abide by these terms and conditions. Thank you for your understanding and cooperation.</h1>
                    </div>

                </div>
            </div>

            <div className='max-w-[1240px] bg-white py-8 px-14 rounded-md mx-auto my-4'>
                <div className='max-w-[1240px] mx-auto flex justify-center'>
                    {/* <img className='w-[500px] mx-auto my-4' src={Girl} alt="Smilling Girl Image" /> */}
                    <div className='flex flex-col justify-center'>
                        <h1 className='flex font-mono justify-start text-xl font-m-bold text-[#e0c184]'>Pretty Salon and Spa Terms and Conditions</h1>
                        <h1 className='flex font-mono justify-start text-sm font-bold text-[#6b6b6b]'>1. Appointments

1.1. Booking: Appointments can be made in person, by phone, or through our online booking system.
1.2. Confirmation: All appointments must be confirmed at least 24 hours in advance.
1.3. Late Arrivals: Clients arriving more than 15 minutes late may need to reschedule, and a late arrival fee may be applied.
1.4. Cancellations: Cancellations must be made at least 24 hours in advance. Failure to do so may result in a cancellation fee.

2. Payments

2.1. Payment Methods: We accept cash, credit/debit cards, and electronic payments.
2.2. Deposits: Some services may require a deposit at the time of booking.
2.3. Refunds: All services are non-refundable. If you are dissatisfied with a service, please notify us within 48 hours, and we will address your concerns.

3. Gift Cards and Vouchers

3.1. Validity: Gift cards and vouchers are valid for 12 months from the date of purchase.
3.2. Non-Transferable: Gift cards and vouchers are non-transferable and cannot be exchanged for cash.
3.3. Lost/Stolen: We are not responsible for lost or stolen gift cards and vouchers.

4. Health and Safety

4.1. Medical Conditions: Clients are required to inform us of any medical conditions or allergies prior to treatment.
4.2. Hygiene: We adhere to strict hygiene protocols to ensure a safe environment for all clients.
4.3. Minors: Clients under the age of 18 must have parental consent for certain treatments.

5. Personal Belongings

5.1. Responsibility: Pretty Salon and Spa is not responsible for the loss or damage of personal belongings.
5.2. Valuables: Clients are advised to keep valuables with them at all times.

6. Conduct

6.1. Behavior: Clients are expected to behave in a respectful and courteous manner towards staff and other clients.
6.2. Right to Refuse Service: We reserve the right to refuse service to anyone displaying inappropriate behavior.

7. Privacy

7.1. Data Protection: We are committed to protecting your privacy and will not share your personal information with third parties without your consent.
7.2. Communication: By providing your contact information, you agree to receive communication from Pretty Salon and Spa regarding your appointments and promotions.

8. Changes to Terms and Conditions

8.1. Modifications: Pretty Salon and Spa reserves the right to modify these terms and conditions at any time. Changes will be posted on our website and in the salon.
8.2. Acceptance: Continued use of our services constitutes acceptance of the updated terms and conditions.

9. Liability

9.1. Waiver: By receiving services at Pretty Salon and Spa, you acknowledge and accept that all treatments are provided at your own risk.
9.2. Limitations: Our liability for any claim related to our services is limited to the amount you paid for the service in question.

By booking an appointment or purchasing a service at Pretty Salon and Spa, you agree to abide by these terms and conditions. Thank you for your understanding and cooperation.</h1>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Terms;
