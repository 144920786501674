import React from 'react'
import Girl from '../assets/Girl.jpg'


// This section is the starting point of the application and this is about to catch the visitors attension 
const Hook = () => {
    return (
        <div className='my-2 mx-4'>
            <div className='max-w-[1240px] bg-white py-8 px-14 rounded-md mx-auto my-4'>
                <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 '>
                    <img className='w-[500px] mx-auto my-4' src={Girl} alt="Smilling Girl Image" />
                    <div className='flex flex-col justify-center'>
                        <h1 className='flex font-mono justify-start text-5xl font-m-bold text-[#e0c184] italic upper'>Pamper</h1>
                        <h1 className='flex font-mono justify-start text-4xl font-bold text-[#6b6b6b]'>yourself you deserve it.</h1>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Hook