import React from 'react'


// In this section I am importing the location of shop from the google map 
// The map needs to be change as map is showing the mall location not the exact shop location 
const Location = () => {
    return (
        <div><div className='my-2 mx-4'>
            <div className='max-w-[1240px] bg-white py-8 px-14 rounded-md mx-auto my-4'>
                <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 '>
                    {/* <img className='w-[500px] mx-auto my-4' src={Girl} alt="Smilling Girl Image" /> */}
                    {/* <h1>Hello</h1> */}
                    <div className='flex flex-col justify-center '>
                        <h1 className='flex font-mono justify-start text-4xl font-m-bold text-[#e0c184] italic upper'>Location & Timings</h1>
                        <h4 className='pt-2'>205 Main Street</h4>
                        <h4>Upper Hutt Central</h4>
                        <h4>The Mall</h4>
                        <h4>Shop no. 702</h4>
                        <h4>Ph No. 04 213 8061</h4>
                        <h4 className='pb-2 '>MON-SAT 9AM - 5:30PM</h4>




                    </div>
                    <div className=''>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3005.502525170731!2d175.07029147626622!3d-41.12355457133451!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d40adb1cab1c653%3A0xc4e055b03785acd0!2sThe%20Mall!5e0!3m2!1sen!2snz!4v1719366163307!5m2!1sen!2snz" width="100%" height="450" style={{}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                </div>
            </div>
        </div></div>
    )
}

export default Location

{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3005.502525170731!2d175.07029147626622!3d-41.12355457133451!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d40adb1cab1c653%3A0xc4e055b03785acd0!2sThe%20Mall!5e0!3m2!1sen!2snz!4v1719366163307!5m2!1sen!2snz" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */ }