import React from "react";
import Navbar from "./components/Navbar";
import Hook from "./components/Hook";
import Gallary from "./components/Gallary";
import Footer from "./components/Footer";
import Location from "./components/Location";
import Booking from "./components/Booking";
import Services from "./Services";
import Home from "./Home";
import About from "./About";
import Terms from "./Terms";
import { Routes, Route, BrowserRouter } from 'react-router-dom';


function App() {
  return (
    // <div>
    //   <Navbar />
    //   <Home />
    //   <Footer />
    // </div>
    < BrowserRouter >

      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />


      </ Routes >
      <Footer />

    </ BrowserRouter >
  );
}

export default App;
