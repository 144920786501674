import React from 'react'
import sample from '../assets/sample.jpeg'
import Eye from '../assets/eye.png'
import Body from '../assets/body.png'
import Nails from '../assets/nails.png'
import Hair from '../assets/hair.png'
import { Link } from 'react-router-dom'
// Getting the pictures from the assets folder 


// This section is showing the some services on the home page and also having the button to navigate to the services page 
const Services = () => {
    return (
        <div><div className='my-2 mx-4'>
            <div className='max-w-[1240px] bg-white py-8 px-14 rounded-md mx-auto my-4'>
                <div className='flex justify-center'><h1 className='flex font-mono justify-start text-4xl font-m-bold text-[#e0c184] italic upper'>Services</h1></div>
                <div className='max-w-[1240px] mx-auto grid md:grid-cols-4 '>
                    
                    <div>
                        <img className='w-[500px] mx-auto my-4 p-2' src={Eye} alt="Eyelash image" />
                        
                    </div>
                    <div>
                        <img className='w-[500px] mx-auto my-4 p-2' src={Body} alt="Body waxing image" />
                        
                    </div><div>
                        <img className='w-[500px] mx-auto my-4 p-2' src={Nails} alt="nails image" />
                        
                    </div><div>
                        <img className='w-[500px] mx-auto my-4 p-2' src={Hair} alt="hair treatment image" />
                        

                    </div>



                </div>
                <div className='flex justify-center'>
                <h1 className='flex font-mono justify-start text-2xl text-[#6b6b6b]'>Checkout all services</h1>
                        </div>
                <div className='flex justify-center'>

                <Link to="services" className='p-4 m-4 bg-[#e0c184] hover:bg-[#6b6b6b] rounded-md'>Services</Link>

                        </div>
            </div>
        </div></div>
    )
}

export default Services