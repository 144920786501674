import React from 'react'
import Girl from './assets/Girl.jpg'
import PP from './assets/PP1.svg'
import PP2 from './assets/PP2.png'
import PP3 from './assets/PP3.svg'
import PP4 from './assets/PP4.svg'





const Services = () => {
    return (

        <div className='my-2 mx-4'>
            <div className='max-w-[1240px] bg-white py-8 px-14 rounded-md mx-auto my-4'>
                {/* <h1 className='flex justify-center font-mono text-4xl text-[#e0c184] italic p-2'>Services</h1> */}
                <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 '>
                    <img className='w-[500px] mx-auto my-4 rounded-md' src={PP} alt="Price List" />
                    <div className='flex flex-col justify-center'>
                        {/* <h1 className='flex font-mono text-4xl text-[#e0c184] italic p-2'>Discover Your True Beauty</h1> */}
                        <p className='flex font-mono justify-start text-lg font-bold p-2'>Face Waxing and Threading</p>
                        <p className='flex font-mono justify-start text-md font- p-2'>Achieve a smooth, flawless complexion with our expert face waxing and threading services. Our skilled estheticians use gentle techniques and high-quality products to remove unwanted facial hair with minimal discomfort.</p>

                        <p className='flex font-mono justify-start text-lg font-bold p-2'>Body Waxing</p>
                        <p className='flex font-mono justify-start text-md font- p-2'>Enjoy silky-smooth skin with our body waxing services. We offer a range of options, including legs, arms, bikini line, and underarms. Our professional team uses premium waxes for long-lasting, beautiful results.</p>

                        <p className='flex font-mono justify-start text-lg font-bold p-2'>Eyelash Extensions</p>
                        <p className='flex font-mono justify-start text-md font- p-2'>Transform your look with our eyelash extensions. Our technicians apply individual extensions to your natural lashes, creating a fuller, longer, and more dramatic appearance. Wake up with gorgeous lashes every day.</p>

                        <div className='flex justify-center'>
                            {/* <h1 className='p-3 bg-white hover:bg-[#e0c184] rounded-md w-20 text-xl font-md-bold m-4'>BOOK</h1> */}
                            <a className='mt-4 px-4 py-2 bg-[#e0c184] hover:bg-[#6b6b6b] rounded-md' href="https://www.fresha.com/a/pretty-salon-and-spa-upper-hutt-205-207-main-street-xocukhjs/all-offer?venue=true" target="_blank" rel="noopener noreferrer">Book</a>



                        </div>
                    </div>

                </div>
            </div>

            <div className='max-w-[1240px] bg-white py-8 px-14 rounded-md mx-auto my-4'>
                {/* <h1 className='flex justify-center font-mono text-4xl text-[#e0c184] italic p-2'>Services</h1> */}
                <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 '>
                    <img className='w-[500px] mx-auto my-4 rounded-md' src={PP2} alt="Price List" />
                    <div className='flex flex-col justify-center'>
                        {/* <h1 className='flex font-mono text-4xl text-[#e0c184] italic p-2'>Discover Your True Beauty</h1> */}
                        <p className='flex font-mono justify-start text-lg font-bold p-2'>Lash and Brow Enhancement</p>
                        <p className='flex font-mono justify-start text-md font- p-2'>Enhance your natural beauty with our lash and brow services at Pretty Salon and Spa. We offer tinting, shaping, and lifting to give your lashes and brows a well-defined, polished look that complements your features perfectly.</p>

                        <p className='flex font-mono justify-start text-lg font-bold p-2'>Waxing for Men</p>
                        <p className='flex font-mono justify-start text-md font- p-2'>Experience smooth, hair-free skin with our specialized waxing services for men. We cater to areas such as the chest, back, and shoulders, using high-quality products and techniques to ensure a comfortable experience and lasting results.</p>

                        <p className='flex font-mono justify-start text-lg font-bold p-2'>Nail Services</p>
                        <p className='flex font-mono justify-start text-md font- p-2'>Indulge in our luxurious nail services, including manicures and gel applications. Our skilled technicians provide meticulous care to keep your nails beautifully groomed and polished, leaving you with hands that look and feel amazing.</p>

                        <div className='flex justify-center'>
                            {/* <h1 className='p-3 bg-white hover:bg-[#e0c184] rounded-md w-20 text-xl font-md-bold m-4'>BOOK</h1> */}
                            <a className='mt-4 px-4 py-2 bg-[#e0c184] hover:bg-[#6b6b6b] rounded-md' href="https://www.fresha.com/a/pretty-salon-and-spa-upper-hutt-205-207-main-street-xocukhjs/all-offer?venue=true" target="_blank" rel="noopener noreferrer">Book</a>



                        </div>
                    </div>

                </div>


            </div>

            <div className='max-w-[1240px] bg-white py-8 px-14 rounded-md mx-auto my-4'>
                {/* <h1 className='flex justify-center font-mono text-4xl text-[#e0c184] italic p-2'>Services</h1> */}
                <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 '>
                    <img className='w-[500px] mx-auto my-4 rounded-md' src={PP3} alt="Price List" />
                    <div className='flex flex-col justify-center'>
                        {/* <h1 className='flex font-mono text-4xl text-[#e0c184] italic p-2'>Discover Your True Beauty</h1> */}
                        <p className='flex font-mono justify-start text-lg font-bold p-2'>Face Massage</p>
                        <p className='flex font-mono justify-start text-md font- p-2'>Relax and rejuvenate with our soothing face massage at Pretty Salon and Spa. Our skilled therapists use gentle techniques to reduce tension, improve circulation, and leave your skin glowing and refreshed.</p>

                        <p className='flex font-mono justify-start text-lg font-bold p-2'>Cosmetic</p>
                        <p className='flex font-mono justify-start text-md font- p-2'>Enhance your beauty with our professional cosmetic services. We offer expert makeup application for any occasion, ensuring you look and feel your best with personalized styles that highlight your natural features.</p>

                        <p className='flex font-mono justify-start text-lg font-bold p-2'>Spray Tan</p>
                        <p className='flex font-mono justify-start text-md font- p-2'>Achieve a beautiful, sun-kissed glow with our spray tan services. Our technicians apply an even, natural-looking tan that enhances your skin tone, giving you a radiant, healthy appearance without the harmful effects of UV exposure.</p>

                        <div className='flex justify-center'>
                            {/* <h1 className='p-3 bg-white hover:bg-[#e0c184] rounded-md w-20 text-xl font-md-bold m-4'>BOOK</h1> */}
                            <a className='mt-4 px-4 py-2 bg-[#e0c184] hover:bg-[#6b6b6b] rounded-md' href="https://www.fresha.com/a/pretty-salon-and-spa-upper-hutt-205-207-main-street-xocukhjs/all-offer?venue=true" target="_blank" rel="noopener noreferrer">Book</a>



                        </div>
                    </div>

                </div>
            </div>

            <div className='max-w-[1240px] bg-white py-8 px-14 rounded-md mx-auto my-4'>
                {/* <h1 className='flex justify-center font-mono text-4xl text-[#e0c184] italic p-2'>Services</h1> */}
                <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 '>
                    <img className='w-[500px] mx-auto my-4 rounded-md' src={PP4} alt="Price List" />
                    <div className='flex flex-col justify-center'>
                        {/* <h1 className='flex font-mono text-4xl text-[#e0c184] italic p-2'>Discover Your True Beauty</h1> */}
                        <p className='flex font-mono justify-start text-lg font-bold p-2'>Hair Services</p>
                        <p className='flex font-mono justify-start text-md font- p-2'>Transform your look with our comprehensive hair services at Pretty Salon and Spa. From stylish cuts and vibrant color treatments to professional blowouts and deep conditioning, our experienced stylists are dedicated to helping you achieve the perfect hairstyle that suits your personality and lifestyle.</p>

                        {/* <p className='flex font-mono justify-start text-lg font-bold p-2'>Body Waxing</p>
                        <p className='flex font-mono justify-start text-md font- p-2'>Enjoy silky-smooth skin with our body waxing services. We offer a range of options, including legs, arms, bikini line, and underarms. Our professional team uses premium waxes for long-lasting, beautiful results.</p>

                        <p className='flex font-mono justify-start text-lg font-bold p-2'>Eyelash Extensions</p>
                        <p className='flex font-mono justify-start text-md font- p-2'>Transform your look with our eyelash extensions. Our technicians apply individual extensions to your natural lashes, creating a fuller, longer, and more dramatic appearance. Wake up with gorgeous lashes every day.</p> */}

                        <div className='flex justify-center'>
                            {/* <h1 className='p-3 bg-white hover:bg-[#e0c184] rounded-md w-20 text-xl font-md-bold m-4'>BOOK</h1> */}
                            <a className='mt-4 px-4 py-2 bg-[#e0c184] hover:bg-[#6b6b6b] rounded-md' href="https://www.fresha.com/a/pretty-salon-and-spa-upper-hutt-205-207-main-street-xocukhjs/all-offer?venue=true" target="_blank" rel="noopener noreferrer">Book</a>



                        </div>
                    </div>

                </div>
            </div>

        </div>






    )
}

export default Services